import React, { useRef, useState } from 'react';
import { Image, Text, View } from 'react-native';

import { hexToRGB } from '../../helpers/utils';
import CloseIcon from './icons/close';

const panorama = require('../../../assets/images/Home/Map1/PointView/Composant_95_11/cam3_noite_corr.webp');

const ICON_SIZE = 50;

const bg = hexToRGB('#000000', 0.1);

export default ({ onClose }: any) => {
  const ref = useRef<View | null>();
  const [width, setWidth] = useState<number>(0);
  Image.getSize(panorama, (width, height) => {
    setWidth(width * ref.current!.clientHeight / height);
  });

  return (
    <View style={{
      marginLeft: 20,
      width: '100%',
      height: `calc(100vh - 120px)`
    }}>
      <View ref={ref}
            style={{ borderRadius: 2, width: `calc(100vw - 306px - 140px)`, height: `calc(100vh - 150px)`, overflow: 'scroll' }}>
        <Image source={panorama}
               style={{
                 width: width,
                 height: `100%`

               }} />
      </View>
      <div style={{
        backgroundColor: bg,
        borderRadius: 5,
        position: 'absolute',
        top: ICON_SIZE / 2 - 5,
        right: ICON_SIZE
      }}
           onClick={onClose}>
        <CloseIcon width={ICON_SIZE}
                   height={ICON_SIZE} />
      </div>
      <View style={{
        alignItems: 'center',
        backgroundColor: bg,
        position: 'absolute',
        bottom: 0,
        width: '100%'
      }}>
        <Text style={{ color: hexToRGB('#ffffff', 0.8), fontSize: 20 }}>← slide →</Text>
      </View>
    </View>
  );
};
