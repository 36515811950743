import * as React from 'react';

function SvgComponent(props: any) {
  return (
    <svg
      data-name="Icon awesome-window-close"
      xmlns="http://www.w3.org/2000/svg"
      width={40.173}
      height={35.152}
      viewBox="0 0 40.173 35.152"
      {...props}>
      <path
        data-name="Icon awesome-window-close"
        d="M36.407 0H3.766A3.767 3.767 0 000 3.766v27.619a3.767 3.767 0 003.766 3.765h32.641a3.767 3.767 0 003.766-3.766V3.766A3.767 3.767 0 0036.407 0zm-6.56 22.794a.966.966 0 010 1.365l-3.177 3.178a.966.966 0 01-1.365 0l-5.218-5.265-5.218 5.265a.966.966 0 01-1.365 0l-3.178-3.178a.966.966 0 010-1.365l5.265-5.218-5.265-5.218a.966.966 0 010-1.365L13.5 7.815a.966.966 0 011.365 0l5.218 5.265L25.3 7.815a.966.966 0 011.365 0l3.178 3.178a.966.966 0 010 1.365l-5.265 5.218z"
        fill="#fff"
        opacity={0.997}
      />
    </svg>
  );
}

export default SvgComponent;
