import React from 'react';
import { View } from 'react-native';

import CustomModal from '../components/Modal';
import NavContainer from './NavContainer';

export const AppContainer = () => {
  return (
    <View style={{ flex: 1 }}>
      <NavContainer />
      <CustomModal />
    </View>
  );
};
