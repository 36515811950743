export const CASABAMBA = require('./Casabamba.webp');
export const TOSA = require('./Tosa.webp');
export const TRAM = require('./Tram.webp');

const ELEMENTS = {
  CASABAMBA: 'CASABAMBA',
  TOSA: 'TOSA',
  TRAM: 'TRAM'
} as const;
export default ELEMENTS;

/* import helper
import _PV, {CASABAMBA, TOSA, TRAM} from './image'
*/
