import { BlurView } from 'expo-blur';
import React, { useState } from 'react';
import { Image, ImageSourcePropType, View } from 'react-native';

import { hexToRGB } from '../../helpers/utils';
import ImageOptim from '../ImageOptim/index';
import ArrowLeft from './icons/arrowLeft';
import CloseIcon from './icons/close';
import PanoramaView from './panoramicView';

const ICON_SIZE = 40;
const PADDING = 15;

const ImageViewer = ({
  infoImage,
  images = [],
  onClose,
  panoramic = false
}: {
  infoImage?: ImageSourcePropType;
  images?: ImageSourcePropType[];
  onClose?: any;
  panoramic?: boolean;
}) => {
  const [currentImage, setCurrentImage] = useState(images[0]);
  const changeImage = (direction: 'left' | 'right') => {
    const index = images.findIndex((img) => img === currentImage);
    //right
    let newIndex = index + 1 > images.length - 1 ? 0 : index + 1;
    if (direction === 'left') {
      newIndex = index - 1 < 0 ? images.length - 1 : index - 1;
    }
    const newImage = images[newIndex];
    setCurrentImage(newImage);
  };

  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}>
      <BlurView
        intensity={infoImage ? 100 : 0}
        style={{
          width: `calc(100% - 60px)`,
          height: `calc(100% - 60px)`,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
          borderRadius: 10,
          padding: PADDING
        }}>
        <View
          style={{
            alignItems: 'center',
            flexGrow: 1,
            height: '100%',
            justifyContent: 'center'
          }}>
          {panoramic ? <PanoramaView {...{ onClose }} /> :
            <>
              <Image style={{
                width: '100%',
                height: '100%',
                borderRadius: 10
              }}
                     source={currentImage}
                     resizeMode='contain' />
              {images?.length > 1 && (
                <>
                  <div style={{
                    position: 'absolute',
                    left: ICON_SIZE / 2 + PADDING,
                    backgroundColor: hexToRGB('#000000', 0.1),
                    borderRadius: ICON_SIZE
                  }}
                       onClick={() => {
                         changeImage('left');
                       }}>
                    <ArrowLeft />
                  </div>
                  <div style={{
                    position: 'absolute',
                    right: ICON_SIZE / 2 + PADDING,
                    backgroundColor: hexToRGB('#000000', 0.1),
                    borderRadius: ICON_SIZE,
                    transform: 'rotateZ(180deg)'
                  }}
                       onClick={() => {
                         changeImage('right');
                       }}>
                    <ArrowLeft />
                  </div>
                </>
              )}
                <div
                  style={{
                    backgroundColor: hexToRGB('#000000', 0.1),
                    borderRadius: 5,
                    position: 'absolute',
                    top: PADDING,
                    right: PADDING * 2 + 5
                  }}
                  onClick={onClose}>
                  <CloseIcon />
                </div>
            </>
          }
        </View>
        {infoImage && <ImageOptim image={infoImage} />}
      </BlurView>
    </View>
  );
};

export default ImageViewer;
