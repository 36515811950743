import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { ICON_NAMES, onElPressed } from '../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../src/helpers/config';
import { ZOOMQUARTIER_TRITONS } from './image';

function SvgComponent() {
  const navigation = useNavigation();

  return (
    <>
      <Image
        style={{
          ...StyleSheet.absoluteFillObject,
          width: '100vw',
          height: '100vh'
        }}
        resizeMode="contain"
        source={ZOOMQUARTIER_TRITONS}
      />
      <svg
        style={{
          ...StyleSheet.absoluteFillObject
        }}
        width="100vw"
        height="100vh"
        viewBox="0 0 1772 1109"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h1772v1109H0z" />
          <circle
            fill={COLORS.clickableArea}
            onClick={navigation.goBack}
            strokeWidth={6}
            cx={71.5}
            cy={75.5}
            r={54.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.ETANG_EQ_PATIO)}
            strokeWidth={6}
            cx={994.5}
            cy={500.5}
            r={54.5}
          />
        </g>
      </svg>
    </>
  );
}

export default SvgComponent;
