import React, { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import cacheAssetsAsync from './cachedAssetsAsync';
import Loading from './src/components/Loader/Loading';
import { AppContainer } from './src/containers/AppContainer';

export default function App() {
  const [isReady, setIsReady] = useState(false);

  useAsyncEffect(async () => {
    await cacheAssetsAsync();
    setIsReady(true);
  }, []);

  if (!isReady) {
    return <Loading />;
  }

  return <AppContainer />;
}
