import * as React from 'react';

function SvgComponent() {
  return (
    <svg
      data-name="Icon awesome-arrow-circle-left"
      xmlns="http://www.w3.org/2000/svg"
      width={34.875}
      height={34.875}
      viewBox="0 0 34.875 34.875">
      <path
        data-name="Icon awesome-arrow-circle-left"
        d="M17.437 34.875a17.438 17.438 0 1117.438-17.438 17.434 17.434 0 01-17.438 17.438zm2.032-10.1l-5.309-5.088H27A1.683 1.683 0 0028.688 18v-1.125a1.683 1.683 0 00-1.687-1.687H14.16l5.309-5.091a1.689 1.689 0 00.028-2.412l-.773-.766a1.681 1.681 0 00-2.384 0l-9.33 9.318a1.681 1.681 0 000 2.384l9.33 9.33a1.681 1.681 0 002.384 0l.773-.766a1.689 1.689 0 00-.028-2.407z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
