export const ADOBESTOCK_295693279_RED = require('./AdobeStock_295693279_RED.webp');
export const CAM2_F_A = require('./cam2_f_a.webp');
export const CCHE_BLACKBOX_APERO_RED = require('./CCHE-BLACKBOX_Apero_RED.webp');
export const ILOTA_MALL_1 = require('./IlotA_mall_1.webp');
export const ILOTA_MALL_2 = require('./IlotA_mall_2.webp');
export const ILOTA_MALL_3 = require('./IlotA_mall_3.webp');
export const ILOTA_MALL_4 = require('./IlotA_mall_4.webp');
export const LOTA_SYNTHESE_CCHE_10_V = require('./lotA_synthese-CCHE_10_V.webp');
export const LOTA_SYNTHESE_CCHE_10 = require('./lotA_synthese-CCHE_10.webp');
export const LOTA_SYNTHESE_CCHE_1_CIEL_AGRANDI = require('./lotA_synthese-CCHE_10.webp'); // FIXME
export const PANEAUX_VITRES = require('./paneaux_vitres.webp');
export const QATMOSPHEREINFO1 = require('./qAtmosphereInfo1.webp');
export const QATMOSPHEREINFO2 = require('./qAtmosphereInfo2.webp');
export const QATMOSPHEREINFO3 = require('./qAtmosphereInfo3.webp');
export const QATMOSPHEREINFO4 = require('./qAtmosphereInfo4.webp');
export const QATMOSPHEREINFO5 = require('./qAtmosphereInfo5.webp');
export const QATMOSPHEREINFO6 = require('./qAtmosphereInfo6.webp');
export const QATMOSPHEREINFO7 = require('./qAtmosphereInfo7.webp');
export const QATMOSPHEREINFO8 = require('./qAtmosphereInfo8.webp');
export const ZOOMQUARTIER_ATMOSPHERES = require('./zoomQuartier_Atmospheres.webp');

const ELEMENTS = {
  ADOBESTOCK_295693279_RED: 'ADOBESTOCK_295693279_RED',
  CAM2_F_A: 'CAM2_F_A',
  CCHE_BLACKBOX_APERO_RED: 'CCHE_BLACKBOX_APERO_RED',
  ILOTA_MALL_1: 'ILOTA_MALL_1',
  ILOTA_MALL_2: 'ILOTA_MALL_2',
  ILOTA_MALL_3: 'ILOTA_MALL_3',
  ILOTA_MALL_4: 'ILOTA_MALL_4',
  LOTA_SYNTHESE_CCHE_10_V: 'LOTA_SYNTHESE_CCHE_10_V',
  LOTA_SYNTHESE_CCHE_10: 'LOTA_SYNTHESE_CCHE_10',
  LOTA_SYNTHESE_CCHE_1_CIEL_AGRANDI: 'LOTA_SYNTHESE_CCHE_1_CIEL_AGRANDI', // FIXME
  PANEAUX_VITRES: 'PANEAUX_VITRES',
  QATMOSPHEREINFO1: 'QATMOSPHEREINFO1',
  QATMOSPHEREINFO2: 'QATMOSPHEREINFO2',
  QATMOSPHEREINFO3: 'QATMOSPHEREINFO3',
  QATMOSPHEREINFO4: 'QATMOSPHEREINFO4',
  QATMOSPHEREINFO5: 'QATMOSPHEREINFO5',
  QATMOSPHEREINFO6: 'QATMOSPHEREINFO6',
  QATMOSPHEREINFO7: 'QATMOSPHEREINFO7',
  QATMOSPHEREINFO8: 'QATMOSPHEREINFO8',
  ZOOMQUARTIER_ATMOSPHERES: 'ZOOMQUARTIER_ATMOSPHERES'
} as const;
export default ELEMENTS;

/* import helper
  import _PV, {ADOBESTOCK_295693279_RED, CAM2_F_A, CCHE_BLACKBOX_APERO_RED, ILOTA_MALL_1, ILOTA_MALL_2, ILOTA_MALL_3, ILOTA_MALL_4, LOTA_SYNTHESE_CCHE_1_CIEL_AGRANDI, LOTA_SYNTHESE_CCHE_10_V, LOTA_SYNTHESE_CCHE_10, PANEAUX_VITRES, QATMOSPHEREINFO1, QATMOSPHEREINFO2, QATMOSPHEREINFO3, QATMOSPHEREINFO4, QATMOSPHEREINFO5, QATMOSPHEREINFO6, QATMOSPHEREINFO7, QATMOSPHEREINFO8, ZOOMQUARTIER_ATMOSPHERES} from '../../assets/images/Quartiers//image'
  */
