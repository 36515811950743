export const BOSQUET = require('./Bosquet.webp');
export const PARKING435 = require('./Parking435.webp');
export const TOSA = require('./Tosa.webp');

const ELEMENTS = {
  BOSQUET: 'BOSQUET',
  PARKING435: 'PARKING435',
  TOSA: 'TOSA'
} as const;
export default ELEMENTS;

/* import helper
import _PV, {BOSQUET, PARKING435, TOSA} from './image'
*/
