export const FABRIQUES_ILOTF_SYNTHESE_5 = require('./Fabriques_IlotF_synthese_5.webp');
export const QFABTIQUESINFO1 = require('./qFabtiquesInfo1.webp');
export const R_F_032 = require('./RF032.webp');
export const ZOOMQUARTIER_FABRIQUES = require('./zoomQuartier_Fabriques.webp');

const ELEMENTS = {
  FABRIQUES_ILOTF_SYNTHESE_5: 'FABRIQUES_ILOTF_SYNTHESE_5',
  QFABTIQUESINFO1: 'QFABTIQUESINFO1',
  R_F_032: 'R_F_032',
  ZOOMQUARTIER_FABRIQUES: 'ZOOMQUARTIER_FABRIQUES'
} as const;
export default ELEMENTS;

/* import helper
  import _PV, {FABRIQUES_ILOTF_SYNTHESE_5, QFABTIQUESINFO1, R_F_032, ZOOMQUARTIER_FABRIQUES} from '../../assets/images/Quartiers//image'
  */
