import React from 'react';
import { ImageSourcePropType } from 'react-native';

type ImageOptimProps = {
  image: ImageSourcePropType;
};

const ImageOptim = ({ image }: ImageOptimProps) => {
  return (
    <img draggable={false}
         style={{ marginLeft: 20, marginRight: 20, width: 306, userSelect: 'none' }}
         src={image} />
  );
};

export default ImageOptim;
