import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { ICON_NAMES, onElPressed } from '../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../src/helpers/config';
import { useBubble } from '../useBubble';
import { ADINA, B_B, INTERCITY, PARKING788, PASSERELLE, TOSA } from './dialogBubbles/image';
import { ZOOMQUARTIER_ATMOSPHERES } from './image';

function SvgComponent() {
  const navigation = useNavigation();
  const { openBubble, closeBubble, bubbleLocation, idOpenedInfo } = useBubble();

  return (
    <View style={{ height: '100vh' }}>
      <Image
        style={{
          ...StyleSheet.absoluteFillObject,
          width: '100vw',
          height: '100vh'
        }}
        resizeMode="contain"
        source={ZOOMQUARTIER_ATMOSPHERES} />
      <svg fill={COLORS.clickableArea}
           onClick={closeBubble}
           style={{
             ...StyleSheet.absoluteFillObject
           }}
           width='100vw'
           height='100vh'
           viewBox='0 0 1772 1108'>
        <g fill='none'
           fillRule='evenodd'>
          <path d='M0 0h1772v1108H0z' />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(PASSERELLE, e)}
                  cx={679}
                  cy={898}
                  r={28} />

          <circle fill={COLORS.clickableArea}
                  onClick={() => onElPressed(ICON_NAMES.CAM2_F_A)}
                  cx={394}
                  cy={315}
                  r={28} />
          <circle fill={COLORS.clickableArea}
                  onClick={() => onElPressed(ICON_NAMES.CCHE_BLACKBOX_APERO_RED)}
                  cx={693}
                  cy={358}
                  r={28} />
          <circle fill={COLORS.clickableArea}
                  onClick={() => onElPressed(ICON_NAMES.ILOTA_MALL_4)}
                  cx={707}
                  cy={554}
                  r={28} />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(B_B, e)}
                  cx={466}
                  cy={386}
                  r={28} />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(ADINA, e)}
                  cx={438}
                  cy={198}
                  r={28} />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(INTERCITY, e)}
                  cx={290}
                  cy={358}
                  r={28} />
          {/* //TODO CHECK CLOSE ICON white on white not visible */}
          <circle fill={COLORS.clickableArea}
                  onClick={() => onElPressed(ICON_NAMES.ADOBESTOCK_295693279_RED)}
                  cx={656}
                  cy={596}
                  r={28} />
          {/* //TODO REMOVE PANO for now */}
          <circle fill={COLORS.clickableArea}
                  onClick={() => onElPressed(ICON_NAMES.LOTA_SYNTHESE_CCHE_1_CIEL_AGRANDI)}
                  cx={827}
                  cy={475}
                  r={28} />

          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.LOTA_SYNTHESE_CCHE_10)}
            cx={883}
            cy={652}
            r={28} />
          <circle fill={COLORS.clickableArea}
                  onClick={() => onElPressed(ICON_NAMES.PANEAUX_VITRES)}
                  cx={886}
                  cy={734}
                  r={28} />
          <circle fill={COLORS.clickableArea}
                  onClick={() => onElPressed(ICON_NAMES.LOTA_SYNTHESE_CCHE_10_V)}
                  cx={1110}
                  cy={680}
                  r={28} />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(TOSA, e)}
                  cx={1029}
                  cy={624}
                  r={28} />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(PARKING788, e)}
                  cx={1183}
                  cy={898}
                  r={28} />
          <circle fill={COLORS.clickableArea}
                  onClick={navigation.goBack}
                  cx={60}
                  cy={64}
                  r={28} />
        </g>
      </svg>
      {idOpenedInfo && (
        <Image source={idOpenedInfo}
               style={{
                 position: 'absolute',
                 width: 193,
                 height: 88,
                 left: bubbleLocation.x,
                 top: bubbleLocation.y
               }} />
      )}
    </View>
  );
}

export default SvgComponent;
