export const QEDIFICEINFO1 = require('./qEdificeInfo1.webp');
export const RI_D4_11_1_082 = require('./RI-D4-11.1-082.webp');
export const RI_D4_11_1_083 = require('./RI-D4-11.1-083.webp');
export const RI_D4_11_1_084 = require('./RI-D4-11.1-084.webp');
export const RI_D5_5_3_041 = require('./RI-D5-5.3-041.webp');
export const RI_D5_5_3_042 = require('./RI-D5-5.3-042.webp');
export const RI_D5_5_3_043 = require('./RI-D5-5.3-043.webp');
export const RI_D6_10_1_072 = require('./RI-D6-10.1-072.webp');
export const RI_D6_10_1_073 = require('./RI-D6-10.1-073.webp');
export const RI_D6_10_1_074 = require('./RI-D6-10.1-074.webp');
export const ZOOMQUARTIER_EDIFICE = require('./zoomQuartier_Edifice.webp');

const ELEMENTS = {
  QEDIFICEINFO1: 'QEDIFICEINFO1',
  RI_D4_11_1_082: 'RI_D4_11_1_082',
  RI_D4_11_1_083: 'RI_D4_11_1_083',
  RI_D4_11_1_084: 'RI_D4_11_1_084',
  RI_D5_5_3_041: 'RI_D5_5_3_041',
  RI_D5_5_3_042: 'RI_D5_5_3_042',
  RI_D5_5_3_043: 'RI_D5_5_3_043',
  RI_D6_10_1_072: 'RI_D6_10_1_072',
  RI_D6_10_1_073: 'RI_D6_10_1_073',
  RI_D6_10_1_074: 'RI_D6_10_1_074',
  ZOOMQUARTIER_EDIFICE: 'ZOOMQUARTIER_EDIFICE'
} as const;
export default ELEMENTS;

/* import helper
import _PV, {QEDIFICEINFO1, RI_D4_11_1_082, RI_D4_11_1_083, RI_D4_11_1_084, RI_D5_5_3_041, RI_D5_5_3_042, RI_D5_5_3_043, RI_D6_10_1_072, RI_D6_10_1_073, RI_D6_10_1_074, ZOOMQUARTIER_EDIFICE} from '../../assets/images/Quartiers//image'
*/
