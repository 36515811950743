import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { ICON_NAMES, onElPressed } from '../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../src/helpers/config';
import { useBubble } from '../useBubble';
import { PARKING557, TOSA_CAROUGE } from './dialogBubbles/image';
import { ZOOMQUARTIER_FABRIQUES } from './image';

function SvgComponent() {
  const navigation = useNavigation();
  const { openBubble, closeBubble, bubbleLocation, idOpenedInfo } = useBubble();

  return (
    <>
      <Image
        style={{
          ...StyleSheet.absoluteFillObject,
          width: '100vw',
          height: '100vh'
        }}
        resizeMode="contain"
        source={ZOOMQUARTIER_FABRIQUES}
      />
      <svg
        fill={COLORS.clickableArea}
        onClick={closeBubble}
        style={{
          ...StyleSheet.absoluteFillObject
        }}
        width="100vw"
        height="100vh"
        viewBox="0 0 1775 1109"
        xmlns="http://www.w3.org/2000/svg">
        <g strokeWidth={6}
           fill='none'
           fillRule='evenodd'>
          <circle fill={COLORS.clickableArea}
                  onClick={() => onElPressed(ICON_NAMES.FABRIQUES_ILOTF_SYNTHESE_5)}
                  cx={1087.5}
                  cy={943.5}
                  r={54.5} />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(TOSA_CAROUGE, e)}
                  cx={1439.5}
                  cy={846.5}
                  r={54.5} />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(PARKING557, e)}
                  cx={1631.5}
                  cy={955.5}
                  r={54.5} />
          <circle fill={COLORS.clickableArea}
                  onClick={navigation.goBack}
                  cx={54.5}
                  cy={72.5}
                  r={54.5} />
        </g>
      </svg>
      {idOpenedInfo && (
        <Image source={idOpenedInfo}
               style={{
                 position: 'absolute',
                 width: idOpenedInfo === TOSA_CAROUGE ? 207 : 193,
                 height: idOpenedInfo === TOSA_CAROUGE ? 169 : 88,
                 left: bubbleLocation.x,
                 top: bubbleLocation.y
               }} />
      )}
    </>
  );
}

export default SvgComponent;
