import React, { useState } from 'react';
import { ImageSourcePropType } from 'react-native';

export interface Coord {
  x: number,
  y: number
}

export const useBubble = () => {
  const [idOpenedInfo, setIdOpenInfo] = useState<ImageSourcePropType | null>();
  const [bubbleLocation, setBubbleLocation] = useState<Coord>({ x: 0, y: 0 });

  const closeBubble = () => {
    if (idOpenedInfo) setIdOpenInfo(null);
  };

  const openBubble = (id: ImageSourcePropType, e: React.MouseEvent<SVGCircleElement>) => {
    setBubbleLocation({ x: e.clientX, y: e.clientY });
    setIdOpenInfo(id);
  };

  return {
    openBubble,
    closeBubble,
    bubbleLocation,
    idOpenedInfo
  };
};
