import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, View } from 'react-native';

import { PAGES_ROUTES } from '../../containers/AppStateMachine';
import { COLORS } from '../../helpers/config';

const MENU_HEIGHT = 200;
const ICON_SIZE = 50;
const ICONS = [
  { image: require('./icons/menu1.png'), navTo: PAGES_ROUTES.HOME1 },
  { image: require('./icons/menu2.png'), navTo: PAGES_ROUTES.HOME2 },
  { image: require('./icons/menu3.png'), navTo: PAGES_ROUTES.HOME3 }
];

const MapMenu = () => {
  const navigation = useNavigation();
  return (
    <View
      style={{
        backgroundColor: COLORS.green,
        height: MENU_HEIGHT,
        position: 'absolute',
        right: 0,
        padding: 5,
        borderRadius: 10,
        paddingRight: 10,
        top: `calc(50% - ${MENU_HEIGHT / 2}px)`,
        justifyContent: 'center',
        alignSelf: 'center'
      }}>
      {ICONS.map((icon, key) => (
        <div
          key={key}
          onClick={() => {
            navigation.navigate(icon.navTo);
          }}>
          <Image
            key={key}
            source={icon.image}
            style={{ width: ICON_SIZE, height: ICON_SIZE, marginVertical: 3 }}
          />
        </div>
      ))}
    </View>
  );
};

export default MapMenu;
