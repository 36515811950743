import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { ICON_NAMES, onElPressed } from '../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../src/helpers/config';
import { useBubble } from '../useBubble';
import { PARKING435 } from './dialogBubbles/image';
import { ZOOMQUARTIER_ETANG } from './image';

function SvgComponent() {
  const navigation = useNavigation();
  const { openBubble, closeBubble, bubbleLocation, idOpenedInfo } = useBubble();

  return (
    <>
      <Image
        style={{
          ...StyleSheet.absoluteFillObject,
          width: '100vw',
          height: '100vh'
        }}
        resizeMode="contain"
        source={ZOOMQUARTIER_ETANG}
      />
      <svg
        fill={COLORS.clickableArea}
        onClick={closeBubble}
        style={{
          ...StyleSheet.absoluteFillObject
        }}
        width="100vw"
        height="100vh"
        viewBox="0 0 1772 1108"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h1772v1108H0z" />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(PARKING435, e)}
                  strokeWidth={6}
                  cx={359.5}
                  cy={625.5}
                  r={54.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.ETANG_ILOTD_SYNTHESE_7)}
            strokeWidth={6}
            cx={910.5}
            cy={702.5}
            r={54.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.RI_D1_2_4_073)}
            strokeWidth={6}
            cx={885.5}
            cy={459.5}
            r={54.5}
          />
          <circle fill={COLORS.clickableArea}
                  onClick={navigation.goBack}
                  strokeWidth={6}
                  cx={54.5}
                  cy={72.5}
                  r={54.5} />
        </g>
      </svg>

      {idOpenedInfo && (
        <Image source={idOpenedInfo}
               style={{
                 position: 'absolute',
                 width: 193,
                 height: 88,
                 left: bubbleLocation.x,
                 top: bubbleLocation.y
               }} />
      )}
    </>
  );
}

export default SvgComponent;
