import HOME1_VIEW_POINTS, {
  CAM10_F,
  CAM7_F,
  ILOTD_SYNTHESE_12,
  ILOTD_SYNTHESE_13,
  ILOTD_SYNTHESE_16,
  ILOTD_SYNTHESE_5,
  ILOTE_SYNTHESE_4,
  ILOTF_SYNTHESE_1,
  ILOTF_SYNTHESE_2,
  ILOTF_SYNTHESE_5,
  R_F_031,
  R_F_036
} from '../../assets/images/Home/Map1/PointView/image';
import HOME3_VIEWPOINTS, {
  _RED,
  CERTIFCATS2000W,
  ILOTD_BIM_1_RED
} from '../../assets/images/Home/Map3/PointView/image';
import ATMOSPHERE_PV, {
  ADOBESTOCK_295693279_RED,
  CAM2_F_A,
  CCHE_BLACKBOX_APERO_RED,
  ILOTA_MALL_1,
  ILOTA_MALL_2,
  ILOTA_MALL_3,
  ILOTA_MALL_4,
  LOTA_SYNTHESE_CCHE_10,
  LOTA_SYNTHESE_CCHE_10_V,
  LOTA_SYNTHESE_CCHE_1_CIEL_AGRANDI,
  PANEAUX_VITRES,
  QATMOSPHEREINFO1,
  QATMOSPHEREINFO2,
  QATMOSPHEREINFO3,
  QATMOSPHEREINFO4,
  QATMOSPHEREINFO5,
  QATMOSPHEREINFO6,
  QATMOSPHEREINFO7,
  QATMOSPHEREINFO8
} from '../../assets/images/Quartiers/Atmosphere/image';
import BELVEDERE_PV, {
  B1,
  B2,
  B3,
  B4,
  B5,
  QBELEVEDEREINFO1
} from '../../assets/images/Quartiers/Belvedere/image';
import CENTRAL_PV, {
  QCENTRALINFO1,
  RI_D1_7_3_072,
  RI_D1_7_3_073,
  RI_D2_1_1_061,
  RI_D2_1_1_062,
  RI_D2_1_1_063,
  RI_D2_3_2_072,
  RI_D6_0_1_061,
  RI_D6_0_1_063
} from '../../assets/images/Quartiers/Central/image';
import DISTRICT_PV, {
  QDISTRICTINFO1,
  RI_D3_8_5_051,
  RI_D3_8_5_052,
  RI_D3_8_5_053,
  RI_D4_0_4_081,
  RI_D4_0_4_082,
  RI_D4_0_4_083,
  RI_D5_0_4_041,
  RI_D5_0_4_042,
  RI_D5_0_4_043
} from '../../assets/images/Quartiers/District/image';
import EDIFICE_PV, {
  QEDIFICEINFO1,
  RI_D4_11_1_082,
  RI_D4_11_1_083,
  RI_D4_11_1_084,
  RI_D5_5_3_041,
  RI_D5_5_3_042,
  RI_D5_5_3_043,
  RI_D6_10_1_072,
  RI_D6_10_1_073,
  RI_D6_10_1_074
} from '../../assets/images/Quartiers/Edifice/image';
import ETANG_PV, {
  ETANG_ILOTD_SYNTHESE_7,
  QETANGINFO1,
  RI_D1_2_4_071,
  RI_D1_2_4_072,
  RI_D1_2_4_073
} from '../../assets/images/Quartiers/Etang/image';
import FABRIQUES_PV, {
  FABRIQUES_ILOTF_SYNTHESE_5,
  QFABTIQUESINFO1,
  R_F_032
} from '../../assets/images/Quartiers/Fabriques/image';
import TRITONS_PV, {
  ETANG_EQ_INTERIEUR_RED,
  ETANG_EQ_PATIO,
  QTRITONSINFO1
} from '../../assets/images/Quartiers/Tritons/image';
import { useStore } from '../components/Modal';
import { getNavigation } from './NavContainer';

export const MAP_BG_IMAGES = {
  HOME1: 'HOME1',
  HOME2: 'HOME2',
  HOME3: 'HOME3'
} as const;

export const QUARTIERS = {
  Atmospheres: 'Atmospheres',
  Belvedere: 'Belvedere',
  District: 'District',
  Central: 'Central',
  Etang: 'Etang',
  Tritons: 'Tritons',
  Edifice: 'Edifice',
  Fabriques: 'Fabriques'
} as const;

export const PAGES_ROUTES = {
  ...MAP_BG_IMAGES,
  FULL_IMAGE: 'FULL_IMAGE',
  ...QUARTIERS
} as const;

enum TYPES {
  MAP = 'MAP',
  IMAGE = 'IMAGE',
  FULL_IMAGE = 'FULL_IMAGE'
}

export const ICON_NAMES = {
  ...HOME1_VIEW_POINTS,
  ...HOME3_VIEWPOINTS,

  ...QUARTIERS,
  ...ATMOSPHERE_PV,
  ...CENTRAL_PV,
  ...DISTRICT_PV,
  ...EDIFICE_PV,
  ...ETANG_PV,
  ...FABRIQUES_PV,
  ...TRITONS_PV,
  ...BELVEDERE_PV
} as const;

export type KeyIconName = keyof typeof ICON_NAMES;

const { displayModal, setContent } = useStore.getState();

export const getIcon = (name: KeyIconName) => APP_ELEMENTS.find((icon) => icon.name === name);

export const onElPressed = (name: KeyIconName) => {
  try {
    const el: any = getIcon(name);
    const {
      nextType: { type, infoImage, images, routeName, panoramic }
    } = el;
    const nav = getNavigation();
    switch (type) {
      case TYPES.IMAGE:
        setContent({ images, infoImage, bgOpacity: infoImage ? 0.8 : 0.75, panoramic });
        displayModal(true);
        break;
      case TYPES.MAP:
      case TYPES.FULL_IMAGE:
        nav.navigate(routeName);
        break;
    }
  } catch (e) {
    console.error(e);
    alert('next type not found for ' + name);
  }
};

const QUARTIER_ELEMENTS = Object.values(QUARTIERS).map((q) => ({
  name: q,
  nextType: {
    type: TYPES.MAP,
    routeName: PAGES_ROUTES[q]
  }
}));

export const APP_ELEMENTS = [
  ...QUARTIER_ELEMENTS,
  // HOME 1 POINT VIEWS
  {
    name: 'Composant_95_2',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_2').default
    }
  },
  {
    name: 'Composant_95_3',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_3').default
    }
  },
  {
    name: 'Composant_95_4',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_4').default
    }
  },
  {
    name: 'Composant_95_5',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_5').default
    }
  },
  {
    name: 'Composant_95_6',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_6').default
    }
  },
  {
    name: 'Composant_95_7',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_7').default
    }
  },
  {
    name: 'Composant_95_8',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_8').default
    }
  },
  {
    name: 'Composant_95_9',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_9').default
    }
  },
  {
    name: 'Composant_95_10',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_10').default
    }
  },
  {
    name: 'Composant_95_11',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_11').default,
      infoImage: QATMOSPHEREINFO1,
      panoramic: true
    }
  },
  {
    name: 'Composant_95_12',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_12').default
    }
  },
  {
    name: 'Composant_95_13',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_13').default
    }
  },
  {
    name: 'Composant_95_14',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_14').default
    }
  },
  {
    name: 'Composant_95_15',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_15').default
    }
  },
  {
    name: 'Composant_95_16',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_16').default
    }
  },
  {
    name: 'Composant_95_17',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_17').default
    }
  },
  {
    name: 'Composant_95_18',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_18').default
    }
  },
  {
    name: 'Composant_95_19',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_19').default
    }
  },
  {
    name: 'Composant_95_20',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_20').default
    }
  },
  {
    name: 'Composant_95_21',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_21').default
    }
  },
  {
    name: 'Composant_95_22',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_22').default
    }
  },
  {
    name: 'Composant_95_23',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_23').default
    }
  },
  {
    name: 'Composant_95_24',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_24').default
    }
  },
  {
    name: 'Composant_95_25',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_25').default
    }
  },
  {
    name: 'Composant_95_26',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_26').default
    }
  },
  {
    name: 'Composant_95_27',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_27').default
    }
  },
  {
    name: 'Composant_95_28',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_28').default
    }
  },
  {
    name: 'Composant_95_29',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_29').default
    }
  },
  {
    name: 'Composant_95_30',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_30').default
    }
  },
  {
    name: 'Composant_95_31',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_31').default
    }
  },
  {
    name: 'Composant_95_32',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_32').default
    }
  },
  {
    name: 'Composant_95_33',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_33').default
    }
  },
  {
    name: 'Composant_95_34',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_34').default
    }
  },
  {
    name: 'Composant_95_35',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_35').default
    }
  },
  {
    name: 'Composant_95_36',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_36').default
    }
  },
  {
    name: 'Composant_95_37',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_37').default
    }
  },
  {
    name: 'Composant_95_38',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_38').default
    }
  },
  {
    name: 'Composant_95_39',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_39').default
    }
  },
  {
    name: 'Composant_95_40',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_40').default
    }
  },
  {
    name: 'Composant_95_41',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_41').default
    }
  },
  {
    name: 'Composant_95_42',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_42').default
    }
  },
  {
    name: 'Composant_95_43',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_43').default
    }
  },
  {
    name: 'Composant_95_44',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_44').default
    }
  },
  {
    name: 'Composant_95_45',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_45').default
    }
  },
  {
    name: 'Composant_95_46',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_46').default
    }
  },
  {
    name: 'Composant_95_47',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_47').default
    }
  },
  {
    name: 'Composant_95_48',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_48').default
    }
  },
  {
    name: 'Composant_95_49',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_49').default
    }
  },
  {
    name: 'Composant_95_50',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_50').default
    }
  },
  {
    name: 'Composant_95_51',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_51').default
    }
  },
  {
    name: 'Composant_95_52',
    nextType: {
      type: TYPES.IMAGE,
      images: require('../../assets/images/Home/Map1/PointView/Composant_95_52').default
    }
  },
  {
    name: 'Composant_95_53',
    nextType: {
      type: TYPES.IMAGE,
      images: require(`../../assets/images/Home/Map1/PointView/Composant_95_53`).default
    }
  },

  // HOME 1 POINT VIEWS end

  // HOME 2 POINT VIEWS
  {
    name: ICON_NAMES.ILOTF_SYNTHESE_5,
    nextType: {
      type: TYPES.IMAGE,
      images: [ILOTF_SYNTHESE_5, R_F_036]
    }
  },

  // HOME 3 POINT VIEWS end

  {
    name: ICON_NAMES.W,
    nextType: {
      type: TYPES.IMAGE,
      images: [CERTIFCATS2000W]
    }
  },
  {
    name: ICON_NAMES._RED,
    nextType: {
      type: TYPES.IMAGE,
      images: [_RED]
    }
  },
  {
    name: ICON_NAMES.ILOTD_BIM_1_RED,
    nextType: {
      type: TYPES.IMAGE,
      images: [ILOTD_BIM_1_RED]
    }
  },
  // ATMOSHPHERE POINTS VIEWS
  {
    name: ICON_NAMES.CAM2_F_A,
    nextType: {
      type: TYPES.IMAGE,
      images: [CAM2_F_A, CAM10_F, CAM7_F],
      infoImage: QATMOSPHEREINFO2
    }
  },
  {
    name: ICON_NAMES.ADOBESTOCK_295693279_RED,
    nextType: {
      type: TYPES.IMAGE,
      images: [ADOBESTOCK_295693279_RED],
      infoImage: QATMOSPHEREINFO6
    }
  },
  {
    name: ICON_NAMES.CCHE_BLACKBOX_APERO_RED,
    nextType: {
      type: TYPES.IMAGE,
      images: [CCHE_BLACKBOX_APERO_RED],
      infoImage: QATMOSPHEREINFO4
    }
  },
  {
    name: ICON_NAMES.ILOTA_MALL_4,
    nextType: {
      type: TYPES.IMAGE,
      images: [ILOTA_MALL_4, ILOTA_MALL_1, ILOTA_MALL_3, ILOTA_MALL_2],
      infoImage: QATMOSPHEREINFO3
    }
  },
  {
    name: ICON_NAMES.LOTA_SYNTHESE_CCHE_1_CIEL_AGRANDI,
    nextType: {
      type: TYPES.IMAGE,
      images: [LOTA_SYNTHESE_CCHE_1_CIEL_AGRANDI],
      infoImage: QATMOSPHEREINFO1,
      panoramic: true
    }
  },
  {
    name: ICON_NAMES.LOTA_SYNTHESE_CCHE_10,
    nextType: {
      type: TYPES.IMAGE,
      images: [LOTA_SYNTHESE_CCHE_10],
      infoImage: QATMOSPHEREINFO8
    }
  },
  {
    name: ICON_NAMES.PANEAUX_VITRES,
    nextType: {
      type: TYPES.IMAGE,
      images: [PANEAUX_VITRES],
      infoImage: QATMOSPHEREINFO7
    }
  },
  {
    name: ICON_NAMES.LOTA_SYNTHESE_CCHE_10_V,
    nextType: {
      type: TYPES.IMAGE,
      images: [LOTA_SYNTHESE_CCHE_10_V],
      infoImage: QATMOSPHEREINFO5
    }
  },

  // BELVEDERE POINTS VIEWS

  {
    name: ICON_NAMES.B1,
    nextType: {
      type: TYPES.IMAGE,
      images: [B1, B2, B3, B4, B5],
      infoImage: QBELEVEDEREINFO1
    }
  },

  // CENTRAL POINTS VIEWS

  {
    name: ICON_NAMES.RI_D2_3_2_072,
    nextType: {
      type: TYPES.IMAGE,
      images: [RI_D2_3_2_072, RI_D6_0_1_061, RI_D6_0_1_063],
      infoImage: QCENTRALINFO1
    }
  },
  {
    name: ICON_NAMES.RI_D1_7_3_073,
    nextType: {
      type: TYPES.IMAGE,
      images: [RI_D1_7_3_073, RI_D1_7_3_072],
      infoImage: QCENTRALINFO1
    }
  },
  {
    name: ICON_NAMES.RI_D2_1_1_063,
    nextType: {
      type: TYPES.IMAGE,
      images: [RI_D2_1_1_063, RI_D2_1_1_061, RI_D2_1_1_062],
      infoImage: QCENTRALINFO1
    }
  },

  // DISTRICT POINTS VIEWS

  {
    name: ICON_NAMES.RI_D3_8_5_053,
    nextType: {
      type: TYPES.IMAGE,
      images: [RI_D3_8_5_053, RI_D3_8_5_051, RI_D3_8_5_052],
      infoImage: QDISTRICTINFO1
    }
  },
  {
    name: ICON_NAMES.RI_D4_0_4_081,
    nextType: {
      type: TYPES.IMAGE,
      images: [RI_D4_0_4_081, RI_D4_0_4_082, RI_D4_0_4_083],
      infoImage: QDISTRICTINFO1
    }
  },
  {
    name: ICON_NAMES.RI_D5_0_4_043,
    nextType: {
      type: TYPES.IMAGE,
      images: [RI_D5_0_4_043, RI_D5_0_4_042, RI_D5_0_4_041],
      infoImage: QDISTRICTINFO1
    }
  },

  // EDIFICE POINTS VIEWS

  {
    name: ICON_NAMES.RI_D5_5_3_041,
    nextType: {
      type: TYPES.IMAGE,
      images: [RI_D5_5_3_041, RI_D5_5_3_042, RI_D5_5_3_043],
      infoImage: QEDIFICEINFO1
    }
  },
  {
    name: ICON_NAMES.RI_D4_11_1_082,
    nextType: {
      type: TYPES.IMAGE,
      images: [RI_D4_11_1_082, RI_D4_11_1_083, RI_D4_11_1_084],
      infoImage: QEDIFICEINFO1
    }
  },
  {
    name: ICON_NAMES.RI_D6_10_1_072,
    nextType: {
      type: TYPES.IMAGE,
      images: [RI_D6_10_1_072, RI_D6_10_1_073, RI_D6_10_1_074],
      infoImage: QEDIFICEINFO1
    }
  },
  // ETANG POINTS VIEWS

  {
    name: ICON_NAMES.RI_D1_2_4_073,
    nextType: {
      type: TYPES.IMAGE,
      images: [RI_D1_2_4_073, RI_D1_2_4_072, RI_D1_2_4_071],
      infoImage: QETANGINFO1
    }
  },

  {
    name: ICON_NAMES.ETANG_ILOTD_SYNTHESE_7,
    nextType: {
      type: TYPES.IMAGE,
      images: [ETANG_ILOTD_SYNTHESE_7, ILOTD_SYNTHESE_12, ILOTD_SYNTHESE_5, ILOTD_SYNTHESE_13],
      infoImage: QETANGINFO1
    }
  },
  // FABRIQUES POINTS VIEWS

  {
    name: ICON_NAMES.FABRIQUES_ILOTF_SYNTHESE_5,
    nextType: {
      type: TYPES.IMAGE,
      images: [
        FABRIQUES_ILOTF_SYNTHESE_5,
        ILOTE_SYNTHESE_4,
        ILOTD_SYNTHESE_16,
        R_F_032,
        ILOTF_SYNTHESE_1,
        ILOTF_SYNTHESE_2,
        R_F_031
      ],
      infoImage: QFABTIQUESINFO1
    }
  },

  // TRITONS POINTS VIEWS

  {
    name: ICON_NAMES.ETANG_EQ_PATIO,
    nextType: {
      type: TYPES.IMAGE,
      images: [ETANG_EQ_PATIO, ETANG_EQ_INTERIEUR_RED],
      infoImage: QTRITONSINFO1
    }
  }
];
