import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import Footer from '../../../components/Footer';
import TitlePink from '../../../components/HomeTitle/index';
import MapMenu from '../../../components/Menu/MapMenu';

const Home2 = () => {
  return (
    <View
      style={{
        width: '100vw',
        height: '100vh'
      }}>
      <Image
        style={{ ...StyleSheet.absoluteFillObject, width: '100%', height: '100%' }}
        source={require('../../../../assets/images/Home/Map2/PLAN02.webp')}
      />
      <MapMenu />
      <TitlePink />

      <Footer {...{ index: 2 }} />
    </View>
  );
};
export default Home2;
