import React from 'react';
import { View } from 'react-native';

import Footer1 from '../../assets/images/Home/Map1/Footer';
import Footer2 from '../../assets/images/Home/Map2/Footer';
import Footer3 from '../../assets/images/Home/Map3/Footer';
import { COLORS } from '../helpers/config';

const Footer = ({ index = 1 }: { index?: number }) => {
  let FooterIndex;
  switch (index) {
    case 2:
      FooterIndex = Footer2;
      break;
    case 3:
      FooterIndex = Footer3;
      break;
    default:
      FooterIndex = Footer1;
  }
  return (
    <View
      style={{
        backgroundColor: COLORS.black,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0
      }}>
      <FooterIndex />
    </View>
  );
};
export default Footer;
