import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { ICON } from '../../../assets/images';

export default function Loading() {
  return <View style={{
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    <img draggable={false}
         style={{ marginTop:"-5%", maxWidth: '50%', maxHeight: '25%' }}
         alt='logo'
         src={ICON} />
    <ActivityIndicator color='#D3777A'
                       size={'large'} />
  </View>;
}
