export const QCENTRALINFO1 = require('./qCentralInfo1.webp');
export const RI_D1_7_3_072 = require('./RI-D1-7.3-072.webp');
export const RI_D1_7_3_073 = require('./RI-D1-7.3-073.webp');
export const RI_D2_1_1_061 = require('./RI-D2-1.1-061.webp');
export const RI_D2_1_1_062 = require('./RI-D2-1.1-062.webp');
export const RI_D2_1_1_063 = require('./RI-D2-1.1-063.webp');
export const RI_D2_3_2_072 = require('./RI-D2-3.2-072.webp');
export const RI_D6_0_1_061 = require('./RI-D6-0.1-061.webp');
export const RI_D6_0_1_063 = require('./RI-D6-0.1-063.webp');
export const ZOOMQUARTIER_CENTRAL = require('./zoomQuartier_Central.webp');

const ELEMENTS = {
  QCENTRALINFO1: 'QCENTRALINFO1',
  RI_D1_7_3_072: 'RI_D1_7_3_072',
  RI_D1_7_3_073: 'RI_D1_7_3_073',
  RI_D2_1_1_061: 'RI_D2_1_1_061',
  RI_D2_1_1_062: 'RI_D2_1_1_062',
  RI_D2_1_1_063: 'RI_D2_1_1_063',
  RI_D2_3_2_072: 'RI_D2_3_2_072',
  RI_D6_0_1_061: 'RI_D6_0_1_061',
  RI_D6_0_1_063: 'RI_D6_0_1_063',
  ZOOMQUARTIER_CENTRAL: 'ZOOMQUARTIER_CENTRAL'
} as const;
export default ELEMENTS;

/* import helper
import _PV, {QCENTRALINFO1, RI_D1_7_3_072, RI_D1_7_3_073, RI_D2_1_1_061, RI_D2_1_1_062, RI_D2_1_1_063, RI_D2_3_2_072, RI_D6_0_1_061, RI_D6_0_1_063, ZOOMQUARTIER_CENTRAL} from '../../assets/images/Quartiers//image'
*/
