export const ETANG_ILOTD_SYNTHESE_7 = require('./Etang_IlotD_synthese_7.webp');
export const QETANGINFO1 = require('./qEtangInfo1.webp');
export const RI_D1_2_4_071 = require('./RI-D1-2.4-071.webp');
export const RI_D1_2_4_072 = require('./RI-D1-2.4-072.webp');
export const RI_D1_2_4_073 = require('./RI-D1-2.4-073.webp');
export const ZOOMQUARTIER_ETANG = require('./zoomQuartier_Etang.webp');

const ELEMENTS = {
  ETANG_ILOTD_SYNTHESE_7: 'ETANG_ILOTD_SYNTHESE_7',
  QETANGINFO1: 'QETANGINFO1',
  RI_D1_2_4_071: 'RI_D1_2_4_071',
  RI_D1_2_4_072: 'RI_D1_2_4_072',
  RI_D1_2_4_073: 'RI_D1_2_4_073',
  ZOOMQUARTIER_ETANG: 'ZOOMQUARTIER_ETANG'
} as const;
export default ELEMENTS;

/* import helper
import _PV, {ETANG_ILOTD_SYNTHESE_7, QETANGINFO1, RI_D1_2_4_071, RI_D1_2_4_072, RI_D1_2_4_073, ZOOMQUARTIER_ETANG} from '../../assets/images/Quartiers//image'
*/
