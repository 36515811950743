import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { ICON_NAMES, onElPressed } from '../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../src/helpers/config';
import { PLAN03 } from './image';

function SvgComponent() {
  return (
    <>
      <Image
        style={{
          ...StyleSheet.absoluteFillObject,
          width: '100vw',
          height: '100vh'
        }}
        source={PLAN03}
      />
      <svg
        viewBox="0 0 5833 3650"
        xmlns="http://www.w3.org/2000/svg"
        width="100vw"
        height="100vh"
        style={{
          ...StyleSheet.absoluteFillObject
        }}>
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h5833v3650H0z" />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.Atmospheres)}
            strokeWidth={46}
            cx={1133}
            cy={1825}
            r={328}
          />

          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES._RED)}
            strokeWidth={46}
            cx={1605}
            cy={1776}
            r={94}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.ILOTD_BIM_1_RED)}
            strokeWidth={46}
            cx={3248.5}
            cy={1469.5}
            r={73.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.Belvedere)}
            strokeWidth={46}
            cx={2562.5}
            cy={1239.5}
            r={257.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.Central)}
            strokeWidth={46}
            cx={2626.5}
            cy={1907.5}
            rx={235}
            ry={237.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.District)}
            strokeWidth={46}
            cx={3577.5}
            cy={1265.5}
            r={158.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.Tritons)}
            strokeWidth={46}
            cx={4325.5}
            cy={1424.5}
            r={257.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.Edifice)}
            strokeWidth={46}
            cx={4376.5}
            cy={2145.5}
            r={257.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.Etang)}
            strokeWidth={46}
            cx={3523.5}
            cy={2295.5}
            r={212.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.Fabriques)}
            strokeWidth={33}
            cx={3458}
            cy={2757}
            rx={968}
            ry={99}
          />
        </g>
      </svg>
    </>
  );
}

export default SvgComponent;
