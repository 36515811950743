import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Image } from 'react-native';

import { PAGES_ROUTES } from '../../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../../src/helpers/config';
import {
  AMENAGEMENTS,
  DECHETS,
  JEUX,
  LIGHTS,
  MOBILITE,
  MOTOS,
  PARKINGS,
  PAYSAGES,
  QUAIS
} from '../../Map1/Footer/image';

const bgImage = require('./FOOTER_02.webp');

function SvgComponent() {
  const navigation = useNavigation();

  const navigateTo = (image?: any) => {
    if (!image) return;
    navigation.navigate(PAGES_ROUTES.FULL_IMAGE, {
      image
    });
  };

  return (
    <>
      <Image
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          bottom: 0
        }}
        resizeMode="contain"
        source={bgImage}
      />
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 5833 656"
        xmlns="http://www.w3.org/2000/svg"
        style={{ zIndex: 1 }}>
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h5833v656H0z" />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateTo(PARKINGS)}
            strokeWidth={15}
            cx={492}
            cy={256.5}
            rx={381}
            ry={41.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateTo(QUAIS)}
            strokeWidth={15}
            cx={492}
            cy={394}
            rx={381}
            ry={54}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateTo(MOBILITE)}
            strokeWidth={15}
            cx={323}
            cy={529.5}
            rx={212}
            ry={41.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateTo(MOTOS)}
            strokeWidth={15}
            cx={1229.5}
            cy={529.5}
            rx={274.5}
            ry={41.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateTo(LIGHTS)}
            strokeWidth={15}
            cx={1229.5}
            cy={394.5}
            rx={274.5}
            ry={41.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateTo(JEUX)}
            strokeWidth={15}
            cx={1229.5}
            cy={256.5}
            rx={274.5}
            ry={41.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateTo(PAYSAGES)}
            strokeWidth={15}
            cx={1923.5}
            cy={394.5}
            rx={274.5}
            ry={41.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateTo(DECHETS)}
            strokeWidth={15}
            cx={1923.5}
            cy={529.5}
            rx={274.5}
            ry={41.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateTo(AMENAGEMENTS)}
            strokeWidth={15}
            cx={2014}
            cy={256.5}
            rx={365}
            ry={41.5}
          />
        </g>
      </svg>
    </>
  );
}

export default SvgComponent;
