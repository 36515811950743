import { useRoute } from '@react-navigation/native';
import React from 'react';
import { ImageSourcePropType, StyleSheet, View } from 'react-native';

import DefaultCliquableBg from '../../../../assets/images/Home/Map3/';
import { W } from '../../../../assets/images/Home/Map3/PointView/image';
import Footer from '../../../components/Footer';
import TitlePink from '../../../components/HomeTitle/index';
import Image from '../../../components/Image';
import MapMenu from '../../../components/Menu/MapMenu';
import { ICON_NAMES, onElPressed } from '../../../containers/AppStateMachine';
import { hexToRGB } from '../../../helpers/utils';

const defaultBg = require('../../../../assets/images/Home/Map3/PLAN03.webp');
const Home3 = () => {
  const route = useRoute() as { params: { image: ImageSourcePropType } };
  const image = route?.params?.image;

  const showOtherMapElements = !image || image === defaultBg;

  return (
    <View
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        backgroundColor: hexToRGB('#000000', 0.1)
      }}>
      {image ? (
        <Image
          style={{ ...StyleSheet.absoluteFillObject, width: '100%', height: '100%' }}
          source={image}
        />
      ) : (
        <DefaultCliquableBg />
      )}

      {showOtherMapElements && (
        <>
          <MapMenu />
          <TitlePink />

          <div
            style={{ flexGrow: 1}}
            onClick={() => {
              onElPressed(ICON_NAMES.W);
            }}>
            <Image
              source={W}
              style={{
                width: 100,
                height: 100,
                borderRadius: 5,
                position: 'absolute',
                left: 50,
                bottom: 250
              }}
            />
          </div>
        </>
      )}

      <Footer {...{ index: 3 }} />
    </View>
  );
};
export default Home3;
