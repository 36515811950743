import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Image } from 'react-native';

import { PAGES_ROUTES } from '../../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../../src/helpers/config';
import {
  _TIMELINE_ATMO,
  _TIMELINE_BELVEDERE_CENTRAL,
  _TIMELINE_CENTRAL,
  _TIMELINE_DISCTRICT,
  _TIMELINE_DISTRICT_1,
  _TIMELINE_DISTRICT_2,
  _TIMELINE_EDIFICE,
  _TIMELINE_FABRIQUES,
  FOOTER_03,
  PLAN_01_ICONES
} from './image';

const bgImage = FOOTER_03;

function SvgComponent() {
  const navigation = useNavigation();

  const navigateToHomeImg = (img?: any) => {
    navigation.navigate(PAGES_ROUTES.HOME3, { image: img });
  };
  return (
    <>
      <Image
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          bottom: 0
        }}
        resizeMode="stretch"
        source={bgImage}
      />
      <svg width="100%" height="100%" viewBox="0 0 5833 643" style={{ zIndex: 1 }}>
        <g fill="none" fillRule="evenodd">
          <circle
            fill={COLORS.clickableArea}
            onClick={() => navigateToHomeImg()}
            strokeWidth={10}
            cx={258.5}
            cy={276.5}
            r={82.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateToHomeImg(_TIMELINE_EDIFICE)}
            strokeWidth={15}
            cx={661}
            cy={359.5}
            rx={210}
            ry={65.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateToHomeImg(_TIMELINE_DISTRICT_1)}
            strokeWidth={15}
            cx={1211}
            cy={359.5}
            rx={210}
            ry={65.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateToHomeImg(_TIMELINE_FABRIQUES)}
            strokeWidth={15}
            cx={1731.5}
            cy={359.5}
            rx={165.5}
            ry={65.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateToHomeImg(_TIMELINE_DISTRICT_2)}
            strokeWidth={15}
            cx={2254.5}
            cy={359.5}
            rx={165.5}
            ry={65.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateToHomeImg(_TIMELINE_DISCTRICT)}
            strokeWidth={15}
            cx={2777.5}
            cy={342}
            rx={165.5}
            ry={62}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateToHomeImg(_TIMELINE_BELVEDERE_CENTRAL)}
            strokeWidth={15}
            cx={3417}
            cy={359.5}
            rx={227}
            ry={65.5}
          />

          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateToHomeImg(_TIMELINE_CENTRAL)}
            strokeWidth={15}
            cx={4149}
            cy={359.5}
            rx={227}
            ry={65.5}
          />
          <ellipse
            fill={COLORS.clickableArea}
            onClick={() => navigateToHomeImg(_TIMELINE_ATMO)}
            strokeWidth={15}
            cx={4886.5}
            cy={359.5}
            rx={262.5}
            ry={65.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => navigateToHomeImg(PLAN_01_ICONES)}
            strokeWidth={10}
            cx={5441.5}
            cy={256.5}
            r={82.5}
          />
        </g>
      </svg>
    </>
  );
}

export default SvgComponent;
