import { Asset } from 'expo-asset';
import { Image } from 'react-native';

export default async function cacheAssetsAsync () {
   const images = [
    require('./assets/images/Home/Map1/Footer/accessParking.webp'),
    require('./assets/images/Home/Map1/Footer/amenagements.webp'),
    require('./assets/images/Home/Map1/Footer/backgroundFooter.webp'),
    require('./assets/images/Home/Map1/Footer/dechets.webp'),
    require('./assets/images/Home/Map1/Footer/Footer.webp'),
    require('./assets/images/Home/Map1/Footer/jeux.webp'),
    require('./assets/images/Home/Map1/Footer/Lights.webp'),
    require('./assets/images/Home/Map1/Footer/Mobilite.webp'),
    require('./assets/images/Home/Map1/Footer/Motos.webp'),
    require('./assets/images/Home/Map1/Footer/Parkings.webp'),
    require('./assets/images/Home/Map1/Footer/Paysages.webp'),
    require('./assets/images/Home/Map1/Footer/Quais.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_53/IlotA_mall_1.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_53/IlotA_mall_2.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_53/IlotA_mall_3.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_52/R-F-036.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_51/RF031.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_50/cam1_noite_f.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_49/IlotF_synthese_1.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_48/IlotF_synthese_2.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_47/IlotF_synthese_5.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_46/IlotD_synthese_16.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_45/IlotF_synthese_4.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_44/MF2_0025_copie.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_44/MF2_0070.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_44/MF2_0124_copie.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_44/MF2_0133.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_44/MF2_0147.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_44/MF2_0155.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_44/MF2_0167_2.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_44/MF2_0191.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_44/MF2_0196.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_43/IlotE_synthese_4.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_42/IlotE_synthese_2.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_41/IlotE_synthese_1.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_40/IlotE_synthese_3.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_39/ETANG_EQ_EXTERIEUR_RED.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_38/ETANG_EQ_PATIO.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_37/ETANG_EQ_INTERIEUR_RED.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_36/RB062RED.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_35/IlotD_synthese_6.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_34/IlotD_synthese_18.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_33/IlotD_synthese_12.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_32/IlotD_synthese_7.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_31/IlotD_synthese_5.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_30/IlotD_synthese_2.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_29/IlotD_synthese_14.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_28/IlotD_synthese_4.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_27/IlotD_synthese_1.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_26/IlotD_synthese_17.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_25/IlotC_synthese_6.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_24/IlotC_synthese_4.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_23/IlotC_synthese_3.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_22/IlotC_synthese_2.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_21/IlotC_synthese_1.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_20/IlotC_synthese_5.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_19/IlotB_synthese_2.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_18/IlotB_synthese_4.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_17/IlotB_synthese_5.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_16/IlotB_synthese_3.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_15/cam2_noite_f.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_14/cam8_f.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_13/CCHEBLACKBOXAperoRed.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_13/urbanprojectsa_qe_atmospheres_imsynthese15.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_12/cam7_f.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_11/cam3_noite_corr.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_10/IlotA_mall_4.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_9/AdobeStock_295693279_RED.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_8/cam1_f.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_7/cam1_f.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_6/cam5_f.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_5/cam9_f.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_4/cam2_f.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_3/cam10_f.webp'),
    require('./assets/images/Home/Map1/PointView/Composant_95_2/cam6_f.webp'),
    require('./assets/images/Home/Map1/PointView/cam7_f.webp'),
    require('./assets/images/Home/Map1/PointView/cam10_f.webp'),
    require('./assets/images/Home/Map1/PointView/IlotD_synthese_5.webp'),
    require('./assets/images/Home/Map1/PointView/IlotD_synthese_12.webp'),
    require('./assets/images/Home/Map1/PointView/IlotD_synthese_13.webp'),
    require('./assets/images/Home/Map1/PointView/IlotD_synthese_16.webp'),
    require('./assets/images/Home/Map1/PointView/IlotE_synthese_4.webp'),
    require('./assets/images/Home/Map1/PointView/IlotF_synthese_1.webp'),
    require('./assets/images/Home/Map1/PointView/IlotF_synthese_1.webp'),
    require('./assets/images/Home/Map1/PointView/IlotF_synthese_2.webp'),
    require('./assets/images/Home/Map1/PointView/IlotF_synthese_5.webp'),
    require('./assets/images/Home/Map1/PointView/R-F-031.webp'),
    require('./assets/images/Home/Map1/PointView/R-F-036.webp'),
    require('./assets/images/Home/Map1/backgroundHeader.webp'),
    require('./assets/images/Home/Map2/PLAN02.webp'),
    require('./assets/images/Home/Map2/Footer/FOOTER_02.webp'),
    require('./assets/images/Home/Map3/PLAN03.webp'),
    require('./assets/images/Home/Map3/Footer/FOOTER_03.webp'),
    require('./assets/images/Home/Map3/Footer/01_Timeline_edifice.webp'),
    require('./assets/images/Home/Map3/Footer/02_Timeline_district_1.webp'),
    require('./assets/images/Home/Map3/Footer/03_Timeline_fabriques.webp'),
    require('./assets/images/Home/Map3/Footer/04_Timeline_district_2.webp'),
    require('./assets/images/Home/Map3/Footer/05_Timeline_disctrict.webp'),
    require('./assets/images/Home/Map3/Footer/06_Timeline_belvedere_central.webp'),
    require('./assets/images/Home/Map3/Footer/07_Timeline_central.webp'),
    require('./assets/images/Home/Map3/Footer/08_Timeline_atmo.webp'),
    require('./assets/images/Home/Map3/Footer/Plan_01_icones.webp'),
    require('./assets/images/Home/Map3/PointView/00000095_RED.webp'),
    require('./assets/images/Home/Map3/PointView/2000w.webp'),
    require('./assets/images/Home/Map3/PointView/Certifcats2000w.webp'),
    require('./assets/images/Home/Map3/PointView/Certifcats2000w_big.webp'),
    require('./assets/images/Home/Map3/PointView/IlotD_BIM_1_RED.webp'),
    require('./assets/images/Quartiers/Atmosphere/AdobeStock_295693279_RED.webp'),
    require('./assets/images/Quartiers/Atmosphere/cam2_f_a.webp'),
    require('./assets/images/Quartiers/Atmosphere/CCHE-BLACKBOX_Apero_RED.webp'),
    require('./assets/images/Quartiers/Atmosphere/IlotA_mall_1.webp'),
    require('./assets/images/Quartiers/Atmosphere/IlotA_mall_2.webp'),
    require('./assets/images/Quartiers/Atmosphere/IlotA_mall_3.webp'),
    require('./assets/images/Quartiers/Atmosphere/IlotA_mall_4.webp'),
    require('./assets/images/Quartiers/Atmosphere/lotA_synthese-CCHE_10.webp'),
    require('./assets/images/Quartiers/Atmosphere/lotA_synthese-CCHE_10_V.webp'),
    require('./assets/images/Quartiers/Atmosphere/paneaux_vitres.webp'),
    require('./assets/images/Quartiers/Atmosphere/qAtmosphereInfo1.webp'),
    require('./assets/images/Quartiers/Atmosphere/qAtmosphereInfo2.webp'),
    require('./assets/images/Quartiers/Atmosphere/qAtmosphereInfo3.webp'),
    require('./assets/images/Quartiers/Atmosphere/qAtmosphereInfo4.webp'),
    require('./assets/images/Quartiers/Atmosphere/qAtmosphereInfo5.webp'),
    require('./assets/images/Quartiers/Atmosphere/qAtmosphereInfo6.webp'),
    require('./assets/images/Quartiers/Atmosphere/qAtmosphereInfo7.webp'),
    require('./assets/images/Quartiers/Atmosphere/qAtmosphereInfo8.webp'),
    require('./assets/images/Quartiers/Atmosphere/zoomQuartier_Atmospheres.webp'),
    require('./assets/images/Quartiers/Atmosphere/dialogBubbles/Adina.webp'),
    require('./assets/images/Quartiers/Atmosphere/dialogBubbles/B_B.webp'),
    require('./assets/images/Quartiers/Atmosphere/dialogBubbles/Casabamba.webp'),
    require('./assets/images/Quartiers/Atmosphere/dialogBubbles/Intercity.webp'),
    require('./assets/images/Quartiers/Atmosphere/dialogBubbles/Parking788.webp'),
    require('./assets/images/Quartiers/Atmosphere/dialogBubbles/Passerelle.webp'),
    require('./assets/images/Quartiers/Atmosphere/dialogBubbles/Tosa.webp'),
    require('./assets/images/Quartiers/Atmosphere/dialogBubbles/Tram.webp'),
    require('./assets/images/Quartiers/Belvedere/B1.webp'),
    require('./assets/images/Quartiers/Belvedere/B2.webp'),
    require('./assets/images/Quartiers/Belvedere/B3.webp'),
    require('./assets/images/Quartiers/Belvedere/B4.webp'),
    require('./assets/images/Quartiers/Belvedere/B5.webp'),
    require('./assets/images/Quartiers/Belvedere/backgroundMap.webp'),
    require('./assets/images/Quartiers/Belvedere/qBelevedereInfo1.webp'),
    require('./assets/images/Quartiers/Belvedere/zoomQuartier_Belvedere.webp'),
    require('./assets/images/Quartiers/Belvedere/dialogBubbles/Casabamba.webp'),
    require('./assets/images/Quartiers/Belvedere/dialogBubbles/Tosa.webp'),
    require('./assets/images/Quartiers/Belvedere/dialogBubbles/Tram.webp'),
    require('./assets/images/Quartiers/Central/qCentralInfo1.webp'),
    require('./assets/images/Quartiers/Central/RI-D1-7.3-072.webp'),
    require('./assets/images/Quartiers/Central/RI-D1-7.3-073.webp'),
    require('./assets/images/Quartiers/Central/RI-D2-1.1-061.webp'),
    require('./assets/images/Quartiers/Central/RI-D2-1.1-062.webp'),
    require('./assets/images/Quartiers/Central/RI-D2-1.1-063.webp'),
    require('./assets/images/Quartiers/Central/RI-D2-3.2-072.webp'),
    require('./assets/images/Quartiers/Central/RI-D6-0.1-061.webp'),
    require('./assets/images/Quartiers/Central/RI-D6-0.1-063.webp'),
    require('./assets/images/Quartiers/Central/zoomQuartier_Central.webp'),
    require('./assets/images/Quartiers/Central/dialogBubbles/Bosquet.webp'),
    require('./assets/images/Quartiers/Central/dialogBubbles/Parking435.webp'),
    require('./assets/images/Quartiers/Central/dialogBubbles/Tosa.webp'),
    require('./assets/images/Quartiers/District/qDistrictInfo1.webp'),
    require('./assets/images/Quartiers/District/RI-D3-8.5-051.webp'),
    require('./assets/images/Quartiers/District/RI-D3-8.5-052.webp'),
    require('./assets/images/Quartiers/District/RI-D3-8.5-053.webp'),
    require('./assets/images/Quartiers/District/RI-D4-0.4-081.webp'),
    require('./assets/images/Quartiers/District/RI-D4-0.4-083.webp'),
    require('./assets/images/Quartiers/District/RI-D4-0.4-082.webp'),
    require('./assets/images/Quartiers/District/RI-D5-0.4-041.webp'),
    require('./assets/images/Quartiers/District/RI-D5-0.4-042.webp'),
    require('./assets/images/Quartiers/District/RI-D5-0.4-043.webp'),
    require('./assets/images/Quartiers/District/dialogBubbles/Nenuphars.webp'),
    require('./assets/images/Quartiers/Edifice/qEdificeInfo1.webp'),
    require('./assets/images/Quartiers/Edifice/RI-D4-11.1-082.webp'),
    require('./assets/images/Quartiers/Edifice/RI-D4-11.1-083.webp'),
    require('./assets/images/Quartiers/Edifice/RI-D4-11.1-084.webp'),
    require('./assets/images/Quartiers/Edifice/RI-D5-5.3-041.webp'),
    require('./assets/images/Quartiers/Edifice/RI-D5-5.3-043.webp'),
    require('./assets/images/Quartiers/Edifice/RI-D5-5.3-042.webp'),
    require('./assets/images/Quartiers/Edifice/RI-D6-10.1-072.webp'),
    require('./assets/images/Quartiers/Edifice/RI-D6-10.1-073.webp'),
    require('./assets/images/Quartiers/Edifice/RI-D6-10.1-074.webp'),
    require('./assets/images/Quartiers/Edifice/dialogBubbles/Grenouilles.webp'),
    require('./assets/images/Quartiers/Edifice/dialogBubbles/Tosa.webp'),
    require('./assets/images/Quartiers/Etang/Etang_IlotD_synthese_7.webp'),
    require('./assets/images/Quartiers/Etang/qEtangInfo1.webp'),
    require('./assets/images/Quartiers/Etang/RI-D1-2.4-071.webp'),
    require('./assets/images/Quartiers/Etang/RI-D1-2.4-072.webp'),
    require('./assets/images/Quartiers/Etang/RI-D1-2.4-073.webp'),
    require('./assets/images/Quartiers/Etang/zoomQuartier_Etang.webp'),
    require('./assets/images/Quartiers/Etang/dialogBubbles/Parking435.webp'),
    require('./assets/images/Quartiers/Fabriques/Fabriques_IlotF_synthese_5.webp'),
    require('./assets/images/Quartiers/Fabriques/qFabtiquesInfo1.webp'),
    require('./assets/images/Quartiers/Fabriques/RF032.webp'),
    require('./assets/images/Quartiers/Fabriques/zoomQuartier_Fabriques.webp'),
    require('./assets/images/Quartiers/Fabriques/dialogBubbles/parking557.webp'),
    require('./assets/images/Quartiers/Fabriques/dialogBubbles/TOSA_carouge.webp'),
    require('./assets/images/Quartiers/Tritons/ETANG-EQ-PATIO.webp'),
    require('./assets/images/Quartiers/Tritons/ETANG_EQ_INTERIEUR_RED.webp'),
    require('./assets/images/Quartiers/Tritons/qTritonsInfo1.webp'),
    require('./assets/images/Quartiers/Tritons/zoomQuartier_Tritons.webp'),
  ]

  await Promise.all([
    ...cacheImages(images)
  ]);
}

function cacheImages (images) {
  return images.map(image => {
   if (typeof image === 'string') {
    return Image.prefetch(image);
   } else {
    return Asset.fromModule(image).downloadAsync();
   }
  });
}
