export const _RED = require('./00000095_RED.webp');
export const W = require('./2000w.webp');
export const CERTIFCATS2000W = require('./Certifcats2000w_big.webp');
export const ILOTD_BIM_1_RED = require('./IlotD_BIM_1_RED.webp');

const ELEMENTS = {
  _RED: '_RED',
  W: 'W',
  CERTIFCATS2000W: 'CERTIFCATS2000W',
  ILOTD_BIM_1_RED: 'ILOTD_BIM_1_RED'
} as const;
export default ELEMENTS;

/* import helper
  import _PV, {_RED, W, CERTIFCATS2000W, ILOTD_BIM_1_RED} from '../../assets/images/Quartiers//image'
  */
