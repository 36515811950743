export const CAM10_F = require('./cam10_f.webp');
export const CAM7_F = require('./cam7_f.webp');
export const ILOTF_SYNTHESE_1 = require('./IlotF_synthese_1.webp');
export const ILOTD_SYNTHESE_5 = require('./IlotD_synthese_5.webp');
export const ILOTD_SYNTHESE_13 = require('./IlotD_synthese_13.webp');
export const ILOTD_SYNTHESE_16 = require('./IlotD_synthese_16.webp');
export const ILOTF_SYNTHESE_5 = require('./IlotF_synthese_5.webp');
export const ILOTE_SYNTHESE_4 = require('./IlotE_synthese_4.webp');
export const R_F_036 = require('./R-F-036.webp');
export const R_F_031 = require('./R-F-031.webp');
export const ILOTF_SYNTHESE_2 = require('./IlotF_synthese_2.webp');
export const ILOTD_SYNTHESE_12 = require('./IlotD_synthese_12.webp');

const ELEMENTS = {
  CAM10_F: 'CAM10_F',
  CAM7_F: 'CAM7_F',
  ILOTF_SYNTHESE_1: 'ILOTF_SYNTHESE_1',
  ILOTD_SYNTHESE_5: 'ILOTD_SYNTHESE_5',
  ILOTD_SYNTHESE_13: 'ILOTD_SYNTHESE_13',
  ILOTD_SYNTHESE_16: 'ILOTD_SYNTHESE_16',
  ILOTF_SYNTHESE_5: 'ILOTF_SYNTHESE_5',
  ILOTE_SYNTHESE_4: 'ILOTE_SYNTHESE_4',
  R_F_036: 'R_F_036',
  R_F_031: 'R_F_031',
  ILOTF_SYNTHESE_2: 'ILOTF_SYNTHESE_2',
  ILOTD_SYNTHESE_12: 'ILOTD_SYNTHESE_12'
} as const;
export default ELEMENTS;
