import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { ICON_NAMES, onElPressed } from '../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../src/helpers/config';
import { useBubble } from '../useBubble';
import { BOSQUET, PARKING435, TOSA } from './dialogBubbles/image';
import { ZOOMQUARTIER_CENTRAL } from './image';

function SvgComponent() {
  const navigation = useNavigation();
  const { openBubble, closeBubble, bubbleLocation, idOpenedInfo } = useBubble();

  return (
    <>
      <Image
        style={{
          ...StyleSheet.absoluteFillObject,
          width: '100vw',
          height: '100vh'
        }}
        resizeMode="contain"
        source={ZOOMQUARTIER_CENTRAL}
      />

      <svg
        fill={COLORS.clickableArea}
        onClick={closeBubble}
        style={{
          ...StyleSheet.absoluteFillObject
        }}
        width="100vw"
        height="100vh"
        viewBox="0 0 1772 1109"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h1772v1109H0z" />
          <circle
            fill={COLORS.clickableArea}
            onClick={navigation.goBack}
            cx={64.5}
            cy={60.5}
            r={24.5}
          />

          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(TOSA, e)}
                  cx={198.5}
                  cy={655.5}
                  r={24.5}
          />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(BOSQUET, e)}
                  cx={821.5}
                  cy={554.5}
                  r={24.5}
          />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(PARKING435, e)}
                  cx={1430.5}
                  cy={732.5}
                  r={24.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.RI_D1_7_3_073)}
            cx={821.5}
            cy={798.5}
            r={24.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.RI_D2_3_2_072)}
            cx={886.5}
            cy={303.5}
            r={24.5}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.RI_D2_1_1_063)}
            cx={1187.5}
            cy={631.5}
            r={24.5}
          />
        </g>
      </svg>
      {idOpenedInfo && (
        <Image source={idOpenedInfo}
               style={{
                 position: 'absolute',
                 width: 193,
                 height: 88,
                 left: bubbleLocation.x,
                 top: bubbleLocation.y
               }} />
      )}
    </>
  );
}

export default SvgComponent;
