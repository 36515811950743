export const _TIMELINE_EDIFICE = require('./01_Timeline_edifice.webp');
export const _TIMELINE_DISTRICT_1 = require('./02_Timeline_district_1.webp');
export const _TIMELINE_FABRIQUES = require('./03_Timeline_fabriques.webp');
export const _TIMELINE_DISTRICT_2 = require('./04_Timeline_district_2.webp');
export const _TIMELINE_DISCTRICT = require('./05_Timeline_disctrict.webp');
export const _TIMELINE_BELVEDERE_CENTRAL = require('./06_Timeline_belvedere_central.webp');
export const _TIMELINE_CENTRAL = require('./07_Timeline_central.webp');
export const _TIMELINE_ATMO = require('./08_Timeline_atmo.webp');
export const FOOTER_03 = require('./FOOTER_03.webp');
export const PLAN_01_ICONES = require('./Plan_01_icones.webp');

const ELEMENTS = {
  _TIMELINE_EDIFICE: '_TIMELINE_EDIFICE',
  _TIMELINE_DISTRICT_1: '_TIMELINE_DISTRICT_1',
  _TIMELINE_FABRIQUES: '_TIMELINE_FABRIQUES',
  _TIMELINE_DISTRICT_2: '_TIMELINE_DISTRICT_2',
  _TIMELINE_DISCTRICT: '_TIMELINE_DISCTRICT',
  _TIMELINE_BELVEDERE_CENTRAL: '_TIMELINE_BELVEDERE_CENTRAL',
  _TIMELINE_CENTRAL: '_TIMELINE_CENTRAL',
  _TIMELINE_ATMO: '_TIMELINE_ATMO',
  FOOTER_03: 'FOOTER_03',
  PLAN_01_ICONES: 'PLAN_01_ICONES'
} as const;
export default ELEMENTS;
