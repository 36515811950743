import React from 'react';
import { View } from 'react-native';
import { WebView } from 'react-native-webview';

import Footer from '../../../components/Footer';
import TitlePink from '../../../components/HomeTitle/index';
import MapMenu from '../../../components/Menu/MapMenu';
import { onElPressed } from '../../../containers/AppStateMachine';

const MapView = () => {
  const onMessageReceived = (message: any) => {
    const event = message.nativeEvent.data;
    if (typeof event !== 'string') return;
    const elementId = event.replace('loupeLabel', '');
    // @ts-ignore
    onElPressed(elementId);
  };

  return (
    <View style={{
      width: '100vw',
      height: '100vh',
      display: 'flex'
    }}>
      <View style={{ flex: 1 }}>
        <WebView javaScriptEnabled={true}
                 domStorageEnabled={true}
                 startInLoadingState={true}
                 originWhitelist={['*']}
                 allowFileAccess={true}
                 allowUniversalAccessFromFileURLs={true}
                 allowFileAccessFromFileURLs={true}
                 source={{ uri: 'https://map-quartier-etang-tiles-liviocoteries-coteries.vercel.app' }}
                 onMessage={onMessageReceived} />
      </View>
      <TitlePink />
      <MapMenu />
      <Footer />
    </View>
  );
};

export default MapView;
