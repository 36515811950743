import { useNavigation, useNavigationState } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';

import AtmosphereMap from '../../assets/images/Quartiers/Atmosphere';
import BelvederImage from '../../assets/images/Quartiers/Belvedere';
import CentralMap from '../../assets/images/Quartiers/Central';
import DistrictMap from '../../assets/images/Quartiers/District';
import EdificeMap from '../../assets/images/Quartiers/Edifice';
import EtangMap from '../../assets/images/Quartiers/Etang';
import FabriquesMap from '../../assets/images/Quartiers/Fabriques';
import TritonsMap from '../../assets/images/Quartiers/Tritons';
import { PAGES_ROUTES } from '../containers/AppStateMachine';
import { PageType, setNavigation } from '../containers/NavContainer';
import FullImagePage from '../pages/FullImage/index';
import Home2 from '../pages/Home/Home2/index';

type ReturnType = { getComponent2Render: () => React.ComponentType<any> };

const useSvgImage = (): ReturnType => {
  const navigation = useNavigation();
  //@ts-ignore
  setNavigation(navigation);
  const { index: routeIndex, routes: navRoutes } = useNavigationState(({ index, routes }) => ({
    index,
    routes
  }));

  const [Component2Render, setComponent2Render] = useState<any>(null);

  useEffect(() => {
    if (Component2Render) return;
    setComponent2Render({ getComponent2Render });
  }, []);

  const getComponent2Render = () => {
    const routeName = navRoutes[routeIndex].name as PageType[keyof PageType];

    switch (routeName) {
      case PAGES_ROUTES.HOME2:
      case PAGES_ROUTES.HOME3:
        return Home2;
      case PAGES_ROUTES.Belvedere:
        return BelvederImage;
      case PAGES_ROUTES.Atmospheres:
        return AtmosphereMap;
      case PAGES_ROUTES.Central:
        return CentralMap;
      case PAGES_ROUTES.District:
        return DistrictMap;
      case PAGES_ROUTES.Edifice:
        return EdificeMap;
      case PAGES_ROUTES.Etang:
        return EtangMap;
      case PAGES_ROUTES.Fabriques:
        return FabriquesMap;
      case PAGES_ROUTES.Tritons:
        return TritonsMap;
      case PAGES_ROUTES.FULL_IMAGE:
        return <FullImagePage />;
    }
  };

  return Component2Render;
};

export default useSvgImage;
