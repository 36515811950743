import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { ICON_NAMES, onElPressed } from '../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../src/helpers/config';
import { useBubble } from '../useBubble';
import { CASABAMBA, TOSA, TRAM } from './dialogBubbles/image';
import { ZOOMQUARTIER_BELVEDERE } from './image';

function SvgComponent() {
  const navigation = useNavigation();
  const { openBubble, closeBubble, bubbleLocation, idOpenedInfo } = useBubble();

  return (
    <div style={{ position: 'relative' }}>
      <Image style={{
        ...StyleSheet.absoluteFillObject,
        width: '100vw',
        height: '100vh'
      }}
             resizeMode='contain'
             source={ZOOMQUARTIER_BELVEDERE} />
      <svg width='100vw'
           height='100vh'
           onClick={closeBubble}
           style={{
             ...StyleSheet.absoluteFillObject
           }}
           viewBox='0 0 1772 1108'
           xmlns='http://www.w3.org/2000/svg'>
        <g fill='none'
           fillRule='evenodd'>
          <path d='M0 0h1772v1108H0z' />
          <circle fill={COLORS.clickableArea}
                  onClick={() => onElPressed(ICON_NAMES.B1)}
                  cx={1003.5}
                  cy={389.5}
                  r={25.5} />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(TOSA, e)}
                  cx={224.5}
                  cy={845.5}
                  r={25.5} />
          <circle fill={COLORS.clickableArea}
                  onClick={navigation.goBack}
                  cx={60.5}
                  cy={64.5}
                  r={25.5} />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(TRAM, e)}
                  cx={1375.5}
                  cy={51.5}
                  r={25.5} />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(CASABAMBA, e)}
                  strokeWidth={11}
                  cx={1500.5}
                  cy={415.5}
                  r={25.5} />
        </g>
      </svg>
      {idOpenedInfo && (
        <Image source={idOpenedInfo}
               style={{
                 position: 'absolute',
                 width: 193,
                 height: 88,
                 left: bubbleLocation.x,
                 top: bubbleLocation.y
               }} />
      )}
    </div>
  );
}

export default SvgComponent;
