import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { COLORS } from '../../helpers/config';

const FullImagePage = () => {
  const route = useRoute() as { params: { image: ImageSourcePropType } };
  const { image } = route.params;

  const navigation = useNavigation();

  return (
      <div onClick={navigation.goBack}>
        <Image style={{ width: '100vw', height: '100vh', backgroundColor: COLORS.black }}
               source={image}
               resizeMode='contain' />
      </div>
  );
};

export default FullImagePage;
