export const QDISTRICTINFO1 = require('./qDistrictInfo1.webp');
export const RI_D3_8_5_051 = require('./RI-D3-8.5-051.webp');
export const RI_D3_8_5_052 = require('./RI-D3-8.5-052.webp');
export const RI_D3_8_5_053 = require('./RI-D3-8.5-053.webp');
export const RI_D4_0_4_081 = require('./RI-D4-0.4-081.webp');
export const RI_D4_0_4_082 = require('./RI-D4-0.4-082.webp');
export const RI_D4_0_4_083 = require('./RI-D4-0.4-083.webp');
export const RI_D5_0_4_041 = require('./RI-D5-0.4-041.webp');
export const RI_D5_0_4_042 = require('./RI-D5-0.4-042.webp');
export const RI_D5_0_4_043 = require('./RI-D5-0.4-043.webp');
export const ZOOMQUARTIER_DISTRICT = require('./zoomQuartier_District.webp');

const ELEMENTS = {
  QDISTRICTINFO1: 'QDISTRICTINFO1',
  RI_D3_8_5_051: 'RI_D3_8_5_051',
  RI_D3_8_5_052: 'RI_D3_8_5_052',
  RI_D3_8_5_053: 'RI_D3_8_5_053',
  RI_D4_0_4_081: 'RI_D4_0_4_081',
  RI_D4_0_4_082: 'RI_D4_0_4_082',
  RI_D4_0_4_083: 'RI_D4_0_4_083',
  RI_D5_0_4_041: 'RI_D5_0_4_041',
  RI_D5_0_4_042: 'RI_D5_0_4_042',
  RI_D5_0_4_043: 'RI_D5_0_4_043',
  ZOOMQUARTIER_DISTRICT: 'ZOOMQUARTIER_DISTRICT'
} as const;
export default ELEMENTS;

/* import helper
import _PV, {QDISTRICTINFO1, RI_D3_8_5_051, RI_D3_8_5_052, RI_D3_8_5_053, RI_D4_0_4_081, RI_D4_0_4_082, RI_D4_0_4_083, RI_D5_0_4_041, RI_D5_0_4_042, RI_D5_0_4_043, ZOOMQUARTIER_DISTRICT} from '../../assets/images/Quartiers//image'
*/
