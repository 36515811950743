import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useRef } from 'react';
import { Animated } from 'react-native';

import FullImagePage from '../pages/FullImage';
import MapView from '../pages/Home/Home1/MapView';
import Home2 from '../pages/Home/Home2';
import Home3 from '../pages/Home/Home3';
import SvgPage from '../pages/Svg/index';
import { PAGES_ROUTES } from './AppStateMachine';

export type PageType = typeof PAGES_ROUTES;

let _navigation: NavigationContainerRef<{}>;
export const setNavigation = (navigation: NavigationContainerRef<{}>) => (_navigation = navigation);
export const getNavigation = () => _navigation;

const Stack = createStackNavigator();

const forFade = ({
                   current
                 }: {
  current: {
    progress: Animated.AnimatedInterpolation;
  };
}) => ({
  cardStyle: {
    opacity: current.progress
  }
});

const OTHER_ROUTES: { routeName: string; component: () => JSX.Element }[] = [
  {
    routeName: PAGES_ROUTES.HOME1,
    component: MapView
  },
  {
    routeName: PAGES_ROUTES.HOME2,
    component: Home2
  },
  {
    routeName: PAGES_ROUTES.HOME3,
    component: Home3
  },
  {
    routeName: PAGES_ROUTES.FULL_IMAGE,
    component: FullImagePage
  }
];

const SVG_ROUTES = Object.values(PAGES_ROUTES).filter(
  (route) => !OTHER_ROUTES.map((r) => r.routeName).includes(route)
);

export default function () {
  const navigationRef = useRef(null);
  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={() => {
        setNavigation(navigationRef.current!);
      }}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {OTHER_ROUTES.map((route, index) => (
          <Stack.Screen key={index}
                        name={route.routeName}
                        component={route.component}
                        options={{ cardStyleInterpolator: forFade }} />
        ))}
        {SVG_ROUTES.map((routeName, index) => {
          return (
            <Stack.Screen key={index}
                          name={routeName}
                          component={SvgPage}
                          options={{ cardStyleInterpolator: forFade }} />
          );
        })}
      </Stack.Navigator>
    </NavigationContainer>
  );
}
