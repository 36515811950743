export const B1 = require('./B1.webp');
export const B2 = require('./B2.webp');
export const B3 = require('./B3.webp');
export const B4 = require('./B4.webp');
export const B5 = require('./B5.webp');
export const BACKGROUNDMAP = require('./backgroundMap.webp');
export const QBELEVEDEREINFO1 = require('./qBelevedereInfo1.webp');
export const ZOOMQUARTIER_BELVEDERE = require('./zoomQuartier_Belvedere.webp');

const ELEMENTS = {
  B1: 'B1',
  B2: 'B2',
  B3: 'B3',
  B4: 'B4',
  B5: 'B5',
  BACKGROUNDMAP: 'BACKGROUNDMAP',
  QBELEVEDEREINFO1: 'QBELEVEDEREINFO1',
  ZOOMQUARTIER_BELVEDERE: 'ZOOMQUARTIER_BELVEDERE'
} as const;
export default ELEMENTS;

/* import helper
import _PV, {B1, B2, B3, B4, B5, BACKGROUNDMAP, QBELEVEDEREINFO1, ZOOMQUARTIER_BELVEDERE} from '../../assets/images/Quartiers//image'
*/
