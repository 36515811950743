export const ETANG_EQ_INTERIEUR_RED = require('./ETANG_EQ_INTERIEUR_RED.webp');
export const ETANG_EQ_PATIO = require('./ETANG-EQ-PATIO.webp');
export const QTRITONSINFO1 = require('./qTritonsInfo1.webp');
export const ZOOMQUARTIER_TRITONS = require('./zoomQuartier_Tritons.webp');

const ELEMENTS = {
  ETANG_EQ_INTERIEUR_RED: 'ETANG_EQ_INTERIEUR_RED',
  ETANG_EQ_PATIO: 'ETANG_EQ_PATIO',
  QTRITONSINFO1: 'QTRITONSINFO1',
  ZOOMQUARTIER_TRITONS: 'ZOOMQUARTIER_TRITONS'
} as const;
export default ELEMENTS;

/* import helper
  import _PV, {ETANG_EQ_INTERIEUR_RED, ETANG_EQ_PATIO, QTRITONSINFO1, ZOOMQUARTIER_TRITONS} from '../../assets/images/Quartiers//image'
  */
