const MF2_0025_copie = require('./MF2_0025_copie.webp');
const MF2_0070 = require('./MF2_0070.webp');
const MF2_0124_copie = require('./MF2_0124_copie.webp');
const MF2_0133 = require('./MF2_0133.webp');
const MF2_0147 = require('./MF2_0147.webp');
const MF2_0155 = require('./MF2_0155.webp');
const MF2_0167_2 = require('./MF2_0167_2.webp');
const MF2_0191 = require('./MF2_0191.webp');
const MF2_0196 = require('./MF2_0196.webp');
export default [
  MF2_0025_copie,
  MF2_0070,
  MF2_0124_copie,
  MF2_0133,
  MF2_0147,
  MF2_0155,
  MF2_0167_2,
  MF2_0191,
  MF2_0196
];
