export const ADINA = require('./Adina.webp');
export const B_B = require('./B_B.webp');
export const CASABAMBA = require('./Casabamba.webp');
export const INTERCITY = require('./Intercity.webp');
export const PARKING788 = require('./Parking788.webp');
export const PASSERELLE = require('./Passerelle.webp');
export const TOSA = require('./Tosa.webp');
export const TRAM = require('./Tram.webp');

const ELEMENTS = {
  ADINA: 'ADINA',
  B_B: 'B_B',
  CASABAMBA: 'CASABAMBA',
  INTERCITY: 'INTERCITY',
  PARKING788: 'PARKING788',
  PASSERELLE: 'PASSERELLE',
  TOSA: 'TOSA',
  TRAM: 'TRAM'
} as const;
export default ELEMENTS;

/* import helper
import _PV, {ADINA, B_B, CASABAMBA, INTERCITY, PARKING788, PASSERELLE, TOSA, TRAM} from './image'
*/
