import React from 'react';
import { ImageSourcePropType, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import create from 'zustand';
import ImageViewer from '../ImageViewer';

type ModalStoreState = {
  display?: boolean;
  infoImage?: ImageSourcePropType;
  images?: ImageSourcePropType[];
  bgOpacity?: number;
  panoramic?: boolean;
};

type ModalStoreMethods = {
  displayModal: (force?: boolean) => void;
  setContent: (data: ModalStoreState) => void;
};
export const useStore = create<ModalStoreState & ModalStoreMethods>((set) => ({
  display: false,
  infoImage: undefined,
  images: [],
  bgOpacity: 0.75,
  panoramic: false,
  setContent: (data: ModalStoreState) => set((state) => ({ panoramic: false, ...state, ...data })),
  displayModal: (force) => set((state) => ({ ...state, display: force ?? !state.display }))
}));

const CustomModal = () => {
  const {
    display,
    displayModal,
    infoImage,
    images,
    bgOpacity = 0.75,
    panoramic = false
  } = useStore((state) => state);
  if (!display) return null;

  const onClose = () => displayModal(false);

  return (
    <Modal
      backdropOpacity={bgOpacity}
      isVisible={display}
      onBackdropPress={onClose}
      coverScreen={false}
      style={styles.modal}>
      <View
        style={{
          width: '100vw',
          height: '100vh',
          margin: 0,
          padding: 0,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <ImageViewer {...{ infoImage, images, onClose, panoramic }} />
      </View>
    </Modal>
  );
};
export default CustomModal;

const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0

    // flex: 1
    //FIX for Android Galaxy Devices ?
    //https://github.com/react-native-modal/react-native-modal#the-backdrop-is-not-completely-filledcovered-on-some-android-devices-galaxy-for-one
  }
});
