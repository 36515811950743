import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { ICON_NAMES, onElPressed } from '../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../src/helpers/config';
import { useBubble } from '../useBubble';
import { GRENOUILLES, TOSA } from './dialogBubbles/image';
import { ZOOMQUARTIER_EDIFICE } from './image';

function SvgComponent() {
  const navigation = useNavigation();
  const { openBubble, closeBubble, bubbleLocation, idOpenedInfo } = useBubble();

  return (
    <>
      <Image
        style={{
          ...StyleSheet.absoluteFillObject,
          width: '100vw',
          height: '100vh'
        }}
        resizeMode="contain"
        source={ZOOMQUARTIER_EDIFICE}
      />
      <svg
        fill={COLORS.clickableArea}
        onClick={closeBubble}
        style={{
          ...StyleSheet.absoluteFillObject
        }}
        width="100vw"
        height="100vh"
        viewBox="0 0 1772 1108"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h1772v1108H0z" />

          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.RI_D5_5_3_041)}
            strokeWidth={11}
            cx={772}
            cy={828}
            r={47}
          />

          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.RI_D4_11_1_082)}
            strokeWidth={11}
            cx={1191}
            cy={285}
            r={47}
          />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(TOSA, e)}
                  strokeWidth={11}
                  cx={1238}
                  cy={1029}
                  r={47}
          />
          <circle fill={COLORS.clickableArea}
                  onClick={(e) => openBubble(GRENOUILLES, e)}
                  strokeWidth={11}
                  cx={1029}
                  cy={554}
                  r={47}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={navigation.goBack}
            strokeWidth={11}
            cx={74}
            cy={67}
            r={47}
          />
          <circle fill={COLORS.clickableArea}
                  onClick={() => onElPressed(ICON_NAMES.RI_D6_10_1_072)}
                  strokeWidth={11}
                  cx={1300}
                  cy={601}
                  r={47} />
        </g>
      </svg>
      {idOpenedInfo && (
        <Image source={idOpenedInfo}
               style={{
                 position: 'absolute',
                 width: 193,
                 height: 88,
                 left: bubbleLocation.x,
                 top: bubbleLocation.y
               }} />
      )}
    </>
  );
}

export default SvgComponent;
