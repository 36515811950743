import React, { useState } from 'react';
import { Image } from 'react-native';

import { TITLE_PINK_IMG } from '../../../assets/images';

const TITLE_ROSE_HEIGHT = 135;

const TitlePink = () => {
  const [dimension, setDimension] = useState<any>({ width: 0, height: 0 });
  Image.getSize(TITLE_PINK_IMG, (width, height) => {
    setDimension({ width, height });
  });

  return (
    <Image
      resizeMode="contain"
      style={{
        height: TITLE_ROSE_HEIGHT,
        width: (TITLE_ROSE_HEIGHT * dimension.width) / dimension.height,
        position: 'absolute',
        top: 0,
        right: 0
      }}
      source={TITLE_PINK_IMG}
    />
  );
};

export default TitlePink;
