import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Image, StyleSheet } from 'react-native';

import { ICON_NAMES, onElPressed } from '../../../../src/containers/AppStateMachine';
import { COLORS } from '../../../../src/helpers/config';
import { B_B } from '../Atmosphere/dialogBubbles/image';
import { useBubble } from '../useBubble';
import { NENUPHARS } from './dialogBubbles/image';
import { ZOOMQUARTIER_DISTRICT } from './image';

function SvgComponent() {
  const navigation = useNavigation();
  const { openBubble, closeBubble, bubbleLocation, idOpenedInfo } = useBubble();

  return (
    <>
      <Image
        style={{
          ...StyleSheet.absoluteFillObject,
          width: '100vw',
          height: '100vh'
        }}
        resizeMode="contain"
        source={ZOOMQUARTIER_DISTRICT}
      />
      <svg
        fill={COLORS.clickableArea}
        onClick={closeBubble}
        style={{
          ...StyleSheet.absoluteFillObject
        }}
        width="100vw"
        height="100vh"
        viewBox="0 0 1772 1125"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h1772v1125H0z" />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.RI_D4_0_4_081)}
            strokeWidth={9}
            cx={819}
            cy={237}
            r={28}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.RI_D5_0_4_043)}
            strokeWidth={9}
            cx={1289}
            cy={637}
            r={28}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={() => onElPressed(ICON_NAMES.RI_D3_8_5_053)}
            strokeWidth={9}
            cx={505}
            cy={667}
            r={28}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={(e) => openBubble(NENUPHARS, e)}
            strokeWidth={9}
            cx={664}
            cy={732}
            r={28}
          />
          <circle
            fill={COLORS.clickableArea}
            onClick={navigation.goBack}
            strokeWidth={9}
            cx={65}
            cy={62}
            r={28}
          />
        </g>
      </svg>
      {idOpenedInfo && (
        <Image source={idOpenedInfo}
               style={{
                 position: 'absolute',
                 width: 193,
                 height: 88,
                 left: bubbleLocation.x,
                 top: bubbleLocation.y
               }} />
      )}
    </>
  );
}

export default SvgComponent;
