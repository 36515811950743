export const GRENOUILLES = require('./Grenouilles.webp');
export const TOSA = require('./Tosa.webp');

const ELEMENTS = {
  GRENOUILLES: 'GRENOUILLES',
  TOSA: 'TOSA'
} as const;
export default ELEMENTS;

/* import helper
import _PV, {GRENOUILLES, TOSA} from './image'
*/
