import React from 'react';
import { View } from 'react-native';

import useSvgImage from '../../hooks/useHomeImage';

// used for all Quartiers because there made of one full image
const SvgPage = () => {
  const res = useSvgImage();
  const Component2Render = res?.getComponent2Render();

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#211d16'
      }}>
      {Component2Render && (
        <Component2Render
          {...{
            width: '100vw',
            height: '100vh'
          }}
        />
      )}
    </View>
  );
};
export default SvgPage;
