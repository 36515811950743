export const ACCESSPARKING = require('./accessParking.webp');
export const AMENAGEMENTS = require('./amenagements.webp');
export const BACKGROUNDFOOTER = require('./backgroundFooter.webp');
export const DECHETS = require('./dechets.webp');
export const FOOTER = require('./Footer.webp');
export const JEUX = require('./jeux.webp');
export const LIGHTS = require('./Lights.webp');
export const MOBILITE = require('./Mobilite.webp');
export const MOTOS = require('./Motos.webp');
export const PARKINGS = require('./Parkings.webp');
export const PAYSAGES = require('./Paysages.webp');
export const QUAIS = require('./Quais.webp');

const ELEMENTS = {
  ACCESSPARKING: 'ACCESSPARKING',
  AMENAGEMENTS: 'AMENAGEMENTS',
  BACKGROUNDFOOTER: 'BACKGROUNDFOOTER',
  DECHETS: 'DECHETS',
  FOOTER: 'FOOTER',
  JEUX: 'JEUX',
  LIGHTS: 'LIGHTS',
  MOBILITE: 'MOBILITE',
  MOTOS: 'MOTOS',
  PARKINGS: 'PARKINGS',
  PAYSAGES: 'PAYSAGES',
  QUAIS: 'QUAIS'
} as const;
export default ELEMENTS;

/* import helper
import _PV, {ACCESSPARKING, AMENAGEMENTS, BACKGROUNDFOOTER, DECHETS, FOOTER, JEUX, LIGHTS, MOBILITE, MOTOS, PARKINGS, PAYSAGES, QUAIS} from '../../assets/images/Quartiers//image'
*/
